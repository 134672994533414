import { getFrontLogger } from 'utils/loggers/FrontLogger';

export function tryToLogContentSecurityViolationsForDebuggingPurpose() {
  if (typeof window.ReportingObserver === 'function') {
    // supported on Chrome only for now
    const observer = new ReportingObserver(
      reports => {
        reports.forEach(({ type, url, body }) => {
          getFrontLogger().warn(
            'Browser report a violation of type {reportType} for {reportUrl}: {reportBody}',
            type,
            url,
            body?.toJSON(),
          );
        });
      },
      { buffered: true },
    );
    observer.observe();
  }
}
